<template>
  <div class="database" v-loading="loading">
    <div class="databaseBox">
      <publishList :archives-list="archivesList"></publishList>
      <div class="noneBox" v-if="!archivesList.length > 0">
        <el-image
          style="width: 20vh; height: 20vh;"
          :src="require('./../../public/img/mycollection/img_none.png')"
          fit="cover"
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <!-- <img src="./../../public/img/mycollection/img_none.png" alt=""> -->
        <span>{{ i18nData.none }}</span>
      </div>
    </div>
    <div class="pagination" v-if="archivesList.length > 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import publishList from "../components/publishList.vue";
import { toTop } from "../common/top.mixin.js";

export default {
  name: "publish",
  mixins: [toTop],
  components: {
    publishList
  },
  data() {
    return {
      loading: true,
      total: 100,
      pageSize: 10,
      currentPage: 1,
      archivesList: [],
      channel: {},
      channel_id: ""
    };
  },
  created() {
    this.$store.state.myActiveIndex = this.$route.name;
    this.getArchives();
  },
  methods: {
    // 分页处理
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getArchives();
      this.backTop();
    },
    async getArchives() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: this.currentPage,
          channel_id: this.channel_id
        };
        let res = await this.$api.https.myArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { archivesList } = res.data;
        this.channel = archivesList.channel;
        this.total = archivesList.total;
        this.archivesList = [...archivesList.data];
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .databaseBox {
    padding: 0 40px;
    .noneBox {
      margin: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 20vh;
        height: 20vh;
      }
      span {
        color: #a1a1a1;
        font-size: 14px;
        border-bottom: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
