<template>
  <div class="listBox">
    <div
      class="listItem"
      v-for="(item, index) in list"
      :index="index"
      :key="item.id"
    >
      <div class="left" v-if="false">
        <el-image
          style="width: 100px; height: 100px;border-radius: 5px;"
          :src="item.image"
          fit="cover"
          lazy
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <!-- <img :src="item.image" alt="" /> -->
      </div>
      <div class="right">
        <div class="titleBox">
          <div class="title">
            <span>
              <el-tag
                type="success"
                v-if="item.status == statusArr[0].status"
                >{{ statusArr[0].label }}</el-tag
              >
              <el-tag
                type="warning"
                v-else-if="item.status == statusArr[1].status"
                >{{ statusArr[1].label }}</el-tag
              >
              <el-tag
                type="danger"
                v-else-if="item.status == statusArr[2].status"
                >{{ statusArr[2].label }}</el-tag
              >
              <el-tag
                type="info"
                v-else-if="item.status == statusArr[3].status"
                >{{ statusArr[3].label }}</el-tag
              >
            </span>
            <span>{{ index + 1 }}. </span>
            <span>{{ item.title }}</span>
            <span v-if="item.title_us">{{ item.title_us }}</span>
            <span v-if="item.title_us_short">({{ item.title_us_short }})</span>
          </div>
          <div class="createDate">
            <span>{{ i18nData.to }}:{{ item.create_date }}</span>
            <span class="modifyBtn">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                @click="toModifyPage(item.id)"
                >修改</el-button
              >
            </span>
          </div>
        </div>
        <!-- <div class="date">
          <div>
            <el-button
              @click="del(index, item.id)"
              type="danger"
              size="mini"
              plain
              >{{ i18nData.delbtn }}</el-button
            >
          </div>
          <div class="other" @click="toPage(item.aid)">
            <span>{{ i18nData.seeText }}</span
            ><span>></span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publishList",
  props: {
    archivesList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    list() {
      if (this.archivesList.length > 0) {
        return this.archivesList;
      }
      return this.defaultList;
    }
  },
  data() {
    return {
      statusArr: [
        {
          label: "已发布",
          status: "normal"
        },
        {
          label: "待审核",
          status: "hidden"
        },
        {
          label: "已驳回",
          status: "rejected"
        },
        {
          label: "已下线",
          status: "pulloff"
        }
      ],
      defaultList: [
        // {
        //   id: 7,
        //   image: require("./../../public/img/database/img1.png"),
        //   title:
        //     "A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting",
        //   description:
        //     "A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting",
        //   create_date: "December 26, 2018"
        // }
      ]
    };
  },
  methods: {
    toPage(index) {
      this.$router.push({
        path: "/detail",
        query: {
          id: index
        }
      });
    },
    toModifyPage(index) {
      this.$router.push({
        path: "/my/mypublish",
        query: {
          id: index
        }
      });
    },
    async del(index, id) {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          id: id
        };
        let res = await this.$api.https.delCollection(params);
        console.log(res);

        this.$message({
          message: res.msg
        });
        if (res.code == 1) {
          this.archivesList.splice(index, 1);
        }

        let { collectionList } = res.data;
        this.channel = collectionList.channel;
        this.total = collectionList.total;
        this.collectionList = [...collectionList.data];
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vue_color: #0495f3;
@mixin other() {
  .other {
    display: flex;
    align-items: center;
    color: #0495f3;
    span:nth-child(1) {
      font-size: 10px;
      font-weight: bold;
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 24px;
    }
  }
}
.listBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 10px;
  padding: 0 0;
  .listItem {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    .left {
      padding: 0 20px 0 0;
      display: flex;
      justify-content: center;
      ::v-deep img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        transition: 0.3s;
      }
      ::v-deep img:hover {
        transform: scale(1.2, 1.2);
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .titleBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-size: 14px;
          font-weight: bold;
          color: $vue_color;
          span:nth-child(1) {
            color: #2b2b2b;
            margin-right: 10px;
          }
        }
        .createDate {
          padding: 10px 0;
          .modifyBtn {
            margin-left: 10px;

            ::v-deep .el-button--primary {
              color: #ffffff !important;
            }
          }
        }
      }

      .date {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .other {
          justify-content: flex-end;
        }
        @include other;
      }
    }
  }
}
</style>
